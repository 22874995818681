<template>
    <div class="settings">
        <van-nav-bar class="bar" :border="false" :title="$t('user.bank_record')" left-arrow fixed placeholder @click-left="onClickLeft" />
        <h3 class="pc-title">{{ $t('user.bank_record') }}</h3>
        <van-list v-model="loading" :finished="finished" :loading-text="$t('user.loading')" :finished-text="$t('user.no_more')" @load="_withdrawList">
            <div class="record-card" v-for="(item, index) in list" :key="item.id" :style="{'margin-bottom': index + 1 >= list.length ? 0 : '12px'}">
                <div>
                    <span>{{ $t('user.types') }}：</span>
                    <span>{{ item.type == 1 ? $t('user.recharge') : $t('user.withdraw_funds') }}</span>
                </div>
                <div>
                    <span>{{ $t('user.amount') }}：</span>
                    <span class="font-num">{{ item.num }}</span>
                </div>
                <div>
                    <span>{{ $t('user.time') }}：</span>
                    <span>{{ item.created_at }}</span>
                </div>
                <div>
                    <span>{{ $t('user.state') }}：</span>
                    <span :style="{'color': getColor(item.status)}">{{ getStatus(item.status) }}</span>
                </div>
            </div>
        </van-list>
    </div>
</template>

<script>
import { withdrawList } from '@/api'

export default {
    data() {
        return {
            list: [],
            loading: false,
            finished: false,
            page: 1,
            limit: 10,
        };
    },
    methods: {
        getColor(status) {
            switch (status) {
                case '0':
                    return '#909399';
                case '1':
                    return '#67C23A';
                case '2':
                    return '#F56C6C';
                default:
                    return '#E6A23C';
            }
        },
        onClickLeft() {
            this.$router.go(-1);
        },
        _withdrawList() {
            this.loading = true;
            withdrawList({
                page: this.page,
                limit: this.limit
            }).then(({ data }) => {
                this.page++;
                this.list = this.list.concat(data.data);
                this.loading = false;
                this.finished = data.current_page === data.last_page;
            })
        },
        getStatus(status) {
            switch (status) {
                case '0':
                    return this.$t('user.pending_review');
                case '1':
                    return this.$t('user.passed');
                case '2':
                    return this.$t('user.rejected');
                default:
                    return '--';
            }
        }
    }
};
</script>

<style lang="less" scoped>
.record-card {
    padding: 10px;
    .font-num {
        font-size: 16px;
        font-weight: 700;
    }
    > div {
        margin-bottom: 12px;
        > span {
            color: #a1a1a1;
            &:first-child {
                color: #909399;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.bar /deep/.van-nav-bar {
    background: var(--bg-color);
}
.bar /deep/ .van-nav-bar__left .van-icon {
    color: var(--font-color);
}
.bar /deep/ .van-nav-bar__title {
    color: var(--font-color);
}
.settings {
    min-height: 100vh;
    background: url(../../../assets/login_bg.png) left top no-repeat
        var(--bg-color);
    background-size: 100%;
    .bar {
        background: none;
    }
}
.pc-title {
    display: none;
}
@media only screen and (min-width: 750px) {
    .settings {
        background: none;
        padding: 42px;
        .bar {
            display: none;
        }
        .pc-title {
            display: block;
            font-weight: 800;
            font-size: 28px;
            color: var(--font-color);
        }
        /deep/.van-list__error-text,
        /deep/.van-list__finished-text,
        /deep/.van-list__loading {
            color: #969799;
            font-size: 28px;
            line-height: 45px;
            text-align: center;
        }
        /deep/.van-list__finished-text {
            margin: 50px auto;
        }
        /deep/ .van-list {
            margin: 20px 0;
        }
        .record-card {
            font-size: 24px;
            background-color: #fff;
            padding: 20px;
            box-shadow: 0px 4px 13px 0px rgba(80, 65, 57, 0.2);
            border-radius: 20px 20px 20px 20px;
            margin-bottom: 20px !important;
            .font-num {
                font-size: 28px;
                font-weight: 700;
            }
            > div {
                margin-bottom: 12px;
                > span {
                    &:first-child {
                        color: #909399;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
</style>
